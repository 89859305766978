export const APP_LOAD = 'APP_LOAD';
export const REDIRECT = 'REDIRECT';
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED';
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED';
export const SET_PAGE = 'SET_PAGE';
export const CHANGE_TAB = 'CHANGE_TAB';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
// export const LOGIN_PAGE_LOADED = 'LOGIN_PAGE_LOADED';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH';
export const VESSEL_PAGE_LOADED = 'VESSEL_PAGE_LOADED';
export const LOAD_CERTIFICATES = 'LOAD_CERTIFICATES';
export const ADD_CERTIFICATE_PAGE_LOADED = 'ADD_CERTIFICATE_PAGE_LOADED';
export const ADD_CERTIFICATE_PAGE_UNLOADED = 'ADD_CERTIFICATE_PAGE_UNLOADED';
export const ADD_CERTIFICATE = 'ADD_CERTIFICATE';
export const UPDATE_FIELD_CERTIFICATE = 'UPDATE_FIELD_CERTIFICATE';
export const ADD_VESSEL_PAGE_LOADED = 'ADD_VESSEL_PAGE_LOADED';
export const ADD_VESSEL_PAGE_UNLOADED = 'ADD_VESSEL_PAGE_UNLOADED';
export const ADD_VESSEL = 'ADD_VESSEL';
export const UPDATE_FIELD_VESSEL = 'UPDATE_FIELD_VESSEL';
export const DELETE_CERTIFICATE = 'DELETE_CERTIFICATE';