// import {
//   HOME_PAGE_LOADED,
//   HOME_PAGE_UNLOADED,
// } from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
